<template>
  <div id="LoginNavBar">
    <van-nav-bar :fixed="true" :title="$route.meta.title"/>
  </div>
</template>

<script>
export default {
  name: "LoginNavBar",
}
</script>

<style scoped>

</style>
