import Vue from 'vue'
import Vuex from 'vuex'
import {commonApi, tenantApi, visitorApi} from "@/api";

Vue.use(Vuex)

export default new Vuex.Store({
    // 状态值定义
    state: null !== window.sessionStorage.getItem('state') ? JSON.parse(window.sessionStorage.getItem('state')) : {
        token: '',
        user: null,
        active: '',
        visitorList: null,
        visitorAuthList: null,
        invitedConfirmList: null,
        approvalList: null,
        invitedList: null,
        tenantList: null,
        areaColumns: [],
        areaEnums: null,
        searchWords:'',
    },
    // 取值方法
    getters: {
        getToken(state) {
            return state.token
        },
        getUser(state) {
            return state.user
        },
        getActive(state) {
            return state.active
        },
        getVisitorList(state) {
            return state.visitorList
        },
        getVisitorAuthList(state) {
            return state.visitorAuthList
        },
        getInvitedConfirmList(state) {
            return state.invitedConfirmList
        },
        getApprovalList(state) {
            return state.approvalList
        },
        getInvitedList(state) {
            return state.invitedList
        },
        getTenantList(state) {
            return state.tenantList
        },
        getAreaColumns(state) {
            return state.areaColumns  
        },
        getAreaEnums(state) {
            return state.areaEnums
        }
    },
    // 唯一可以修改state值的方法，同步阻塞
    mutations: {
        updateToken(state, token) {
            state.token = token
        },
        updateUser(state, user) {
            state.user = user
            if (user !== undefined && user !== null) {
                state.token = user.accessToken
            }
        },
        updateActive(state, active) {
            state.active = active
        },
        updateVisitorList(state, visitorList) {
            state.visitorList = visitorList
        },
        updateVisitorAuthList(state, visitorAuthList) {
            state.visitorAuthList = visitorAuthList
        },
        updateInvitedConfirmList(state, invitedConfirmList) {
            state.invitedConfirmList = invitedConfirmList
        },
        updateApprovalList(state, approvalList) {
            state.approvalList = approvalList
        },
        updateInvitedList(state, invitedList) {
            state.invitedList = invitedList
        },
        updateTenantList(state, tenantList) {
            state.tenantList = tenantList
        },
        updateAreaColumns(state, areaColumns) {
            state.areaColumns = areaColumns  
        },
        updateAreaEnums(state, areaEnums) {
            state.areaEnums = areaEnums
        }
    },
    // 异步调用mutations方法
    actions: {
        asyncUpdateToken(context, token) {
            context.commit('updateToken', token)
        },
        asyncUpdateUser(context, user) {
            context.commit('updateUser', user)
        },
        asyncUpdateActive(context, active) {
            context.commit('updateActive', active)
        },
        asyncUpdateVisitorList(context, param) {
            let searchWords = "";
            if (typeof param != 'undefined'&& !param._isVue) {
                searchWords = param;
            }
            visitorApi.getVisitorList(searchWords).then(res => {
                if (res.status === 200) {
                    context.commit('updateVisitorList', res.data.data.list)
                }
            })
        },
        asyncUpdateVisitorAuthList(context, param) {
            let searchWords = "";
            if (typeof param != 'undefined'&& !param._isVue) {
                searchWords = param;
            }
            visitorApi.getVisitorAuthList(searchWords).then(res => {
                if (res.status === 200) {
                    context.commit('updateVisitorAuthList', res.data.data.visitorAuthList)
                }
            })
        },
        asyncClearStore(context) {
            context.commit('updateToken',"")
            context.commit('updateUser', null)
            context.commit('updateActive', null)
            context.commit('updateVisitorList', null)
            context.commit('updateInvitedConfirmList', null)
            context.commit('updateVisitorAuthList', null)
            context.commit('updateApprovalList', null)
            context.commit('updateInvitedList', null)
        },
        asyncUpdateInvitedConfirmList(context, param) {
            let searchWords = "";
            if (typeof param != 'undefined'&& !param._isVue) {
                searchWords = param;
            }
            visitorApi.getInvitedConfirmList(searchWords).then(res => {
                context.commit('updateInvitedConfirmList', res.data.data.list)
            })
        },
        asyncUpdateApprovalList(context, param) {
            let searchWords = "";
            if (typeof param != 'undefined'&& !param._isVue) {
                searchWords = param;
            }
            tenantApi.getApprovalList(searchWords).then(res => {
                context.commit('updateApprovalList', res.data.data.approvalList)
            })
        },
        asyncUpdateInvitedList(context, param) {
            let searchWords = "";
            if (typeof param != 'undefined'&& !param._isVue) {
                searchWords = param;
            }
            tenantApi.getInvitedList(searchWords).then(res => {
                context.commit('updateInvitedList', res.data.data.invitedList)
            })
        },
        asyncUpdateTenantList(context) {
            commonApi.getTenantList().then(res => {
                context.commit('updateTenantList', res.data.data)
                const areaColumns = []
                res.data.data.forEach(item => {
                    areaColumns.push(item.name)
                })
                context.commit('updateAreaColumns',areaColumns)
                const areaEnums = []
                res.data.data.forEach(item => {
                    areaEnums.push({key: item.name, value: item.tenantId})
                })
                context.commit('updateAreaEnums', areaEnums)
            })
        }
    },
    modules: {}
})
