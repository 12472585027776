<template>
  <div id="OnlyBackNavBar">
    <van-nav-bar :fixed="true" :title="$route.meta.title" left-text="返回" left-arrow @click-left="$router.go(-1)"/>
  </div>
</template>

<script>
export default {
  name: "OnlyBackNavBar",
}
</script>

<style scoped>

</style>
