import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'

import 'vant/lib/index.css'
import 'vant/lib/icon/local.css'
import './assets/common.scss'
import './utils/ArrayUtil'
import './mock/index'
import './mock/TenantMock'

Vue.config.productionTip = false
Vue.use(Vant)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
