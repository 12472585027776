import {http, http2} from '@/utils/Request'

const commonApi = {
    getTenantList() {
        return http.get('/common/tenant')
    }
}

const userApi = {
    smsSend(phone) {
        return http.get('/user/smsSend?phone=' + phone)
    },
    remoteLogin(encryptPhone) {
        return http.get('/user/remoteLogin?phone=' + encryptPhone)
    },
    userLogin(param) {
        return http.post('/user/login', param)
    },
    userLogout() {
        return http.get('/user/logout')
    },
    userUpdate(param) {
        return http.post('/user/update', param)
    },
    tenantCheck(param) {
        return http.post('/user/tenantCheck', param)
    }
}

const visitorApi = {
    getVisitorList(searchWords) {
        return http.get('/visitor/list?searchWords='+searchWords)
    },
    getVisitorAuthList(searchWords) {
        return http.get('/visitor/authList?searchWords='+searchWords)
    },
    getVisitorListCount() {
        return http.get('/visitor/listCount')
    },
    getVisitorInfoById(id) {
        return http.get('/visitor/info/' + id)
    },
    visitorAccessRequest(param) {
        return http.post('/visitor/accessRequest', param)
    },
    getvisitorAuthInfoById(id) {
        return http.get('/visitor/authInfo/' + id)
    },
    getInvitedConfirmList(searchWords) {
        return http.get('/visitor/invitedConfirmList?searchWords='+searchWords)
    },
    getInvitedConfirmById(id) {
        return http.get('/visitor/invitedConfirm/' + id)
    },
    confirmInvited(param) {
        return http.post('/visitor/confirmInvited', param)
    }
}

const tenantApi = {
    getApprovalList(searchWords) {
        return http.get('/tenant/approvalList?searchWords='+searchWords)
    },
    getApprovalDetailById(id) {
        return http.get('/tenant/approvalDetail/' + id)
    },
    approvalAgree(id) {
        return http.get('/tenant/approval/agree/' + id)
    },
    approvalReject(param) {
        return http.post('/tenant/approval/reject', param)
    },
    getInvitedList(searchWords) {
        return http.get('/tenant/invitedList?searchWords='+searchWords)
    },
    invite(param) {
        return http.post('/tenant/invite', param)
    },
    getInviteInfoById(id) {
        return http.get('/tenant/inviteInfo/' + id)
    },
    revokeInvited(id) {
        return http.get('/tenant/revoke/' + id)
    }
}

const otherApi = {
    // 用户信息查询接口
    queryByPhone(phone) {
        return http2.get('/visitor/user/queryByPhone?phone=' + phone)
    },
    // 租客校验接口
    tenantCheck(param) {
        return http2.post('/visitor/tenant/check', param)
    },
    blackListUserCheck(param) {
        return http2.post('visitor/user/blackListUserCheck', param)
    }
}

export {
    commonApi,
    userApi,
    visitorApi,
    tenantApi,
    otherApi
}
