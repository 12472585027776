<template>
  <div id="InvitedConfirmListNavBar">
    <van-nav-bar :fixed="true" :title="$route.meta.title" @click-left="refreshVisitList">
      <template #left>
        <van-icon name="search" size="16" class="margin-r-4"/>
        <span class="van-nav-bar__text">刷新</span>
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {Toast} from "vant";

export default {
  name: "InvitedConfirmListNavBar",
  methods: {
    ...mapActions(['asyncUpdateInvitedConfirmList']),
    refreshVisitList() {
      this.asyncUpdateInvitedConfirmList()
      Toast('数据已刷新')
    }
  }
}
</script>

<style scoped>

</style>
