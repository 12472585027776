<template>
  <div id="InvitedListNavBar">
    <van-nav-bar :fixed="true" :title="$route.meta.title" @click-left="refreshInvitedList" @click-right="toInvite">
      <template #left>
        <van-icon name="search" size="16" class="margin-r-4"/>
        <span class="van-nav-bar__text">刷新</span>
      </template>
      <template #right>
        <span class="van-nav-bar__text margin-r-4">新增</span>
        <van-icon name="add-o" size="16"/>
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {Toast} from "vant";

export default {
  name: "InvitedListNavBar",
  methods: {
    ...mapActions(['asyncUpdateInvitedList']),
    refreshInvitedList() {
      this.asyncUpdateInvitedList()
      Toast('数据已刷新')
    },
    toInvite() {
      this.$router.replace({path: '/invite'})
    }
  }
}
</script>

<style scoped>

</style>
