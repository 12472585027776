<template>
  <div class="nav-bar">
    <!--  头部导航栏  -->
    <!--  登录页面导航  -->
    <SingleTitleNavBar v-if="$route.name === 'login' || $route.name === 'my'"></SingleTitleNavBar>
    
    <!--  访问列表页面导航，提供刷新访问列表功能和访问申请页面跳转操作  -->
    <VisitorListNavBar v-else-if="$route.name === 'visitor-list'"/>
    <!--  受邀确认列表页面导航，提供刷新访问列表功能  -->
    <VisitConfirmListNavBar v-else-if="$route.name === 'invited-confirm-list'"/>
  
    <!--  访问列表页面导航，提供刷新功能  -->
    <ApprovalListNavBar v-else-if="$route.name === 'approval-list'"></ApprovalListNavBar>
    <!--  邀请记录页面导航，提供刷新，新增功能  -->
    <InvitedListNavBar v-else-if="$route.name === 'invited-list'"></InvitedListNavBar>
  
    <!--  授权列表页面导航，提供刷新功能  -->
    <VisitorAuthListNavBar v-else-if="$route.name === 'visitor-auth-list'"></VisitorAuthListNavBar>
    
    <!--  其它导航，提供返回调用页操作  -->
    <OnlyBackNavBar v-else/>
  </div>
</template>

<script>
import VisitorListNavBar from "@/components/navBar/VisitorListNavBar";
import LoginNavBar from "@/components/navBar/LoginNavBar";
import OnlyBackNavBar from "@/components/navBar/OnlyBackNavBar";
import VisitConfirmListNavBar from "@/components/navBar/InvitedConfirmListNavBar";
import ApprovalListNavBar from "@/components/navBar/ApprovalListNavBar";
import InvitedListNavBar from "@/components/navBar/InvitedListNavBar";
import SingleTitleNavBar from "@/components/navBar/SingleTitleNavBar";
import VisitorAuthListNavBar from "@/components/navBar/VisitorAuthListNavBar";

export default {
  name: 'nav-bar',
  components: {
    VisitorAuthListNavBar,
    SingleTitleNavBar,
    InvitedListNavBar,
    ApprovalListNavBar, VisitConfirmListNavBar, OnlyBackNavBar, LoginNavBar, VisitorListNavBar},
  props: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
