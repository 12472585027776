import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import('../views/Login.vue'),
        meta: {
            keepAlive: true,
            title: '首页',
            active: null
        }
    },
    {
        path: '/visitorList',
        name: 'visitor-list',
        component: () => import('../views/visit/VisitorList.vue'),
        meta: {
            title: '访问列表',
            active: 'visitorList'
        }
    },
    {
        path: '/visitorAccessRequest',
        name: 'visitor-access-request',
        component: () => import('../views/visit/VisitorAccessRequest.vue'),
        meta: {
            title: '访客申请',
            active: 'visitorList'
        }
    },
    {
        path: '/repeatVisitRequest',
        name: 'repeat-visit-request',
        component: () => import('../views/visit/RepeatVisitRequest.vue'),
        meta: {
            title: '再次访问',
            active: 'visitorList'
        }
    },
    {
        path: '/visitRequestInfo',
        name: 'visit-request-info',
        component: () => import('../views/visit/VisitRequestInfo.vue'),
        meta: {
            title: '查看详情',
            active: 'visitorList'
        }
    },
    {
        path: '/invitedConfirmList',
        name: 'invited-confirm-list',
        component: () => import('../views/visit/InvitedConfirmList.vue'),
        meta: {
            title: '受邀确认',
            active: 'invitedConfirmList'
        }
    },
    {
        path: '/inviteDetails',
        name: 'invite-details',
        component: () => import('../views/visit/InviteDetails.vue'),
        meta: {
            title: '邀请详情',
            active: 'invitedConfirmList'
        }
    },
    {
        path: '/invitedConfirm',
        name: 'invited-confirm',
        component: () => import('../views/visit/InvitedConfirm.vue'),
        meta: {
            title: '邀请确认',
            active: 'invitedConfirmList'
        }
    },
    {
        path: '/my',
        name: 'my',
        component: () => import('../views/my/My.vue'),
        meta: {
            title: '我的',
            active: 'my'
        }
    },
    {
        path: '/updateMyInfo',
        name: 'updateMyInfo',
        component: () => import('../views/my/UpdateMyInfo.vue'),
        meta: {
            title: '我的-更新',
            active: 'my'
        }
    },
    {
        path: '/approvalList',
        name: 'approval-list',
        component: () => import('../views/tenant/ApprovalList'),
        meta: {
            title: '我要审批',
            active: 'approval-list'
        }
    },
    {
        path: '/approval',
        name: 'approval',
        component: () => import('../views/tenant/Approval'),
        meta: {
            title: '预约审批',
            active: 'approval-list'
        }
    },
    {
        path: '/approvalDetail',
        name: 'approval-detail',
        component: () => import('../views/tenant/ApprovalDetail'),
        meta: {
            title: '预约详情',
            active: 'approval-list'
        }
    },
    {
        path: '/invitedList',
        name: 'invited-list',
        component: () => import('../views/tenant/InvitedList'),
        meta: {
            title: '邀请记录',
            active: 'invitedList'
        }
    },
    {
        path: '/invite',
        name: 'invite',
        component: () => import('../views/tenant/Invite'),
        meta: {
            title: '邀请访客',
            active: 'invitedList'
        }
    },
    {
        path: '/invited-detail',
        name: 'invited-detail',
        component: () => import('../views/tenant/InvitedDetail'),
        meta: {
            title: '邀请详情',
            active: 'invitedList'
        }
    },
    {
        path: '/repeatInvite',
        name: 'repeat-invite',
        component: () => import('../views/tenant/RepeatInvite'),
        meta: {
            title: '邀请访客',
            active: 'invitedList'
        }
    },
    {
        path: '/visitorAuthList',
        name: 'visitor-auth-list',
        component: () => import('../views/visitorAuth/VisitorAuthList'),
        meta: {
            title: '授权列表',
            active: 'visitor-auth-list'
        }
    },
    {
        path: '/visitorAuthDetail',
        name: 'visitor-auth-detail',
        component: () => import('../views/visitorAuth/VisitorAuthDetail'),
        meta: {
            title: '预约详情',
            active: 'visitor-auth-list'
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

/* 设置路由守卫，做动态配置 */
router.beforeEach((to, from, next) => {
    // 设置页面title
    if (to.meta.title) {
        document.title = to.meta.title
    }
    // 设置底部菜单栏激活
    next()
})

export default router
