import axios from 'axios'
import {Toast} from "vant"
import router from "@/router"

const http = axios.create({
    baseURL: '/api',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    responseType: 'json'
})

const http2 = axios.create({
    baseURL: '/otherApi',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    responseType: 'json'
})

// 添加请求拦截器
http.interceptors.request.use(function (config) {
    const token = window.sessionStorage.getItem('token')
    // 设置token
    config.headers.set('Authorization', token)
    // 在发送请求之前做些什么
    // 需要校验一下token,如果没有token则路由到登录页面
    return config;
}, function (error) {
    // 请请求错误做些什么
    return Promise.reject(error)
})

http.interceptors.response.use(function (response) {
    const code = response.data.code;
    // 用户未登录
    if (code === 2001 || code === 2007 || code === 2010) {
        Toast(response.data.message);
        // 跳转到登录页
        if (router.currentRoute.path !== "/") {
            router.push('/')
        }
    }
    return response;
}, function (error) {
    // 将用户踢到登录页，接后台时打开下面两行的注释
    router.push('/')
    // Toast('服务异常，请重新登录！')
    // 对响应错误做点什么
    return Promise.reject(error)
})

export {
    http, http2
}
