<template>
  <div class="bottom-menu">
    <!--  访客  -->
    <van-tabbar v-model="active" class="footer" v-if="user !== null && user.role === 1">
      <van-tabbar-item replace name="visitorList" icon="guide-o" to="/visitorList">申请访问</van-tabbar-item>
      <van-tabbar-item replace name="invitedConfirmList" icon="chat-o" to="/invitedConfirmList">受邀确认</van-tabbar-item>
      <van-tabbar-item replace name="my" icon="user-o" to="/my">我的</van-tabbar-item>
    </van-tabbar>
    <!--  租客  -->
    <van-tabbar v-model="active" class="footer" v-else-if="user !== null && user.role === 2">
      <van-tabbar-item replace name="visitorList" icon="guide-o" to="/visitorList">申请访问</van-tabbar-item>
      <van-tabbar-item replace name="invitedConfirmList" icon="chat-o" to="/invitedConfirmList">受邀确认</van-tabbar-item>
      <van-tabbar-item replace name="approval-list" icon="completed" to="/approvalList">我要审批</van-tabbar-item>
      <van-tabbar-item replace name="invited-list" icon="records" to="/invitedList">我要邀请</van-tabbar-item>
      <van-tabbar-item replace name="my" icon="user-o" to="/my">我的</van-tabbar-item>
    </van-tabbar>
    <!--  租客-行政  -->
    <van-tabbar v-model="active" class="footer" v-else-if="user !== null && user.role === 3">
      <van-tabbar-item replace name="visitorList" icon="guide-o" to="/visitorList">申请访问</van-tabbar-item>
      <van-tabbar-item replace name="invitedConfirmList" icon="chat-o" to="/invitedConfirmList">受邀确认</van-tabbar-item>
      <van-tabbar-item replace name="approval-list" icon="completed" to="/approvalList">我要审批</van-tabbar-item>
      <van-tabbar-item replace name="invited-list" icon="records" to="/invitedList">我要邀请</van-tabbar-item>
      <van-tabbar-item replace name="my" icon="user-o" to="/my">我的</van-tabbar-item>
    </van-tabbar>
    <!--  租客-前台  -->
    <van-tabbar v-model="active" class="footer" v-else-if="user !== null && user.role === 4">
      <van-tabbar-item replace name="visitorList" icon="guide-o" to="/visitorList">申请访问</van-tabbar-item>
      <van-tabbar-item replace name="invitedConfirmList" icon="chat-o" to="/invitedConfirmList">受邀确认</van-tabbar-item>
      <van-tabbar-item replace name="visitor-auth-list" icon="completed" to="/visitorAuthList">访客授权</van-tabbar-item>
      <van-tabbar-item replace name="my" icon="user-o" to="/my">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: 'bottom-menu',
  computed:{
    ...mapState([
        'user'
    ]),
    ...mapGetters([
      'getActive'
    ]),
    active: {
      get() {
        return this.getActive
      },
      set(val) {
        this.asyncUpdateActive(val)
      }
    }
  },
  components: {},
  props: {},
  methods: {
    ...mapActions(['asyncUpdateActive']),
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .van-tabbar--fixed {
      bottom: 20px;
  }
</style>
