<template>
  <div id="app">
    <!-- 开启顶部安全区适配 -->
    <nav-bar safe-area-inset-top />
    
    <!-- 内容区域 -->
    <router-view :class="{'content':$route.name !== 'login','content_full':$route.name === 'login'}"/>
    
    <!--  底部菜单区域  -->
    <bottom-menu v-if="$route.name !== 'login'"></bottom-menu>

    <!-- 开启底部安全区适配 -->
    <van-number-keyboard safe-area-inset-bottom />
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import NavBar from "@/components/NavBar";
import BottomMenu from "@/components/BottomMenu";

export default {
  components: {BottomMenu, NavBar},
  data() {
    return {}
  },
  computed: {
    ...mapState(['user']),
  },
  // 页面加载完执行函数
  mounted() {
    window.addEventListener('unload', this.saveState)
    this.asyncUpdateTenantList()
  },
  created() {
    let u = navigator.userAgent
    if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 || /(iPhone|iPad|ipod|iOS)/i.test(u)) {
      window.addEventListener('resize', this.checkKeyboard)
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.checkKeyboard);
  },
  methods: {
    ...mapActions(['asyncUpdateTenantList']),
    saveState() {
      window.sessionStorage.setItem('state', JSON.stringify(this.$store.state))
    },
    checkKeyboard() {
      if (document.activeElement.tagName === 'INPUT') {
        window.setTimeout(function () {
          // document.activeElement.scrollIntoView()
          document.activeElement.scrollIntoView({
            behavior: "smooth", // 平滑滚动
            block: "nearest",
            inline: "nearest"
          });
        }, 10)
      }
    },
  }
}
</script>

<style lang="scss">

</style>
