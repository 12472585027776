/**
 * 扩展Array支持remove方法
 *
 * @param dx 索引
 * @arrange
 *  <br>方法:Array.remove(dx) 通过遍历,重构数组
 *  <br>功能:删除数组元素.
 *  <br>参数:dx删除元素的下标.
 */
Array.prototype.remove = function (dx) {
    if (isNaN(dx) || dx > this.length) { return false; }
    for (var i = 0, n = 0; i < this.length; i++) {
        if (this[i] != this[dx]) {
            this[n++] = this[i]
        }
    }
    this.length -= 1
}
